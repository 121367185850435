<template>
  <div>
    <div v-if="pageUsage === 'category' && !cartSettings">
      <ProductMenuCategory
        :item="item"
        :content="content"
        :selectedMenuID="selectedMenuID"
        @showSubcategoriesList="showSubcategoriesList"
        @openDialogProductsList="openDialogProductsList"
        @OpenCartSettings = "OpenCartSettings"
      />
    </div>
    <div v-if="pageUsage === 'subcategory' && !cartSettings">
      <ProductMenuSubcategory
        :content="content"
        :selectedCategory="selectedCategory"
        :selectedMenuID="selectedMenuID"
        :listSubcategories="listSubcategories"
        @openDialogProductsList="openDialogProductsList"
        @openCategory="openCategory"
        @OpenCartSettings = "OpenCartSettings"
      />`
    </div>
    <div v-if="pageUsage === 'product' && !cartSettings">
      <ProductMenuProduct
        :content="content"
        :selectedMenuID="selectedMenuID"
        :selectedCategory="selectedCategory"
        @openCategory="openCategoryNext"
        @OpenCartSettings = "OpenCartSettings"
        :item="item"
      />
    </div>
    <div v-if="cartSettings">
      <div style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
      " >
        <div style="flex:1;">
        <v-btn
                class="object__submit btn btn_primary"
                color="primary"
                outlined
                small
                solo
                @click="closeCartSettings"
        >
          <v-icon>mdi-chevron-left</v-icon> Back to {{pageUsage}}
        </v-btn>
        </div>
      </div>
      <div style="
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
      " >
       <div class="label"> Cart settings</div>
      </div>
      <div style="display: flex;align-items: center;margin-top:30px;">
        <div class="label"> Order Settings</div>
      </div>
        <div style="margin-bottom: 15px;">
          <div style="display: flex;flex-direction: column;margin-bottom: 15px;">
            <label class="label" style="margin-bottom: 15px;">Emails for send order</label>
            <div v-for="(email,emailIndex) in arrEmails" :key="emailIndex">
              <v-text-field v-model="content.emails[email]" dense outlined style="max-width: 400px;" append-outer-icon="mdi-close-circle-outline" @click:append-outer="deleteEmail(emailIndex,content.emails)"></v-text-field>
            </div>
            <v-btn color="primary" small outlined @click="addEmail(content.emails)" style="max-width: 150px;" >+Add email</v-btn>
          </div>
          <div style="display: flex;flex-direction: column;">
            <label class="label" style="margin-bottom: 15px;">Telegram users for send order</label>
            <div v-for="user in arrTelegrams" :key="user">
              <v-select :value="content.telegrams[user]" item-text="first_name" item-disabled="disabled" item-value="id" return-object :items="telegramUsersAvaliable(content.telegrams[user],content.telegrams)"  dense outlined append-outer-icon="mdi-close-circle-outline" @click:append-outer="deleteUser(user,content.telegrams)">
                <template slot="selection" slot-scope="data">
                  {{data.item.first_name}} {{data.item.last_name}}
                </template>
                <template v-slot:item="{ item }">
                  {{item.first_name}} {{item.last_name}}
                </template>
              </v-select>
            </div>
            <v-btn color="primary" small outlined @click="addUser(content.telegrams,enabledTelegramUsers)" style="max-width: 150px;" :disabled="enabledTelegramUsers.length === 0">+Add user</v-btn>
            <div v-if="enabledTelegramUsers.length === 0">
              You need create more users <span @click="goToTelegramUsers" style="color:#007aff;cursor: pointer;">here</span>
            </div>
          </div>
        </div>
    <div class="label"> Table Settings</div>
    <div style="display: flex;align-items: center;">
      <label class="label" style="margin-right: 15px;width:200px;">Enable for table</label>
      <v-switch v-model="content.enable_for_table"></v-switch>
    </div>
      <div v-if="content.enable_for_table" style="margin-bottom: 15px;">
        <div style="display: flex;flex-direction: column;margin-bottom: 15px;">
          <label class="label" style="margin-bottom: 15px;">Emails for send order</label>
          <div v-for="(email,emailIndex) in arrEmailsTable" :key="emailIndex">
            <v-text-field v-model="content.emails_table[email]" dense outlined style="max-width: 400px;" append-outer-icon="mdi-close-circle-outline" @click:append-outer="deleteEmail(emailIndex,content.emails_table)"></v-text-field>
          </div>
          <v-btn color="primary" small outlined @click="addEmail(content.emails_table)" style="max-width: 150px;" >+Add email</v-btn>
        </div>
        <div style="display: flex;flex-direction: column;">
          <label class="label" style="margin-bottom: 15px;">Telegram users for send order</label>
          <div v-for="user in arrTelegramsTable" :key="user">
            <v-select :value="content.telegrams_table[user]" item-text="first_name" item-disabled="disabled" item-value="id" return-object :items="telegramUsersAvaliable(content.telegrams_table[user],content.telegrams_table)"  dense outlined append-outer-icon="mdi-close-circle-outline" @click:append-outer="deleteUser(user,content.telegrams_table)">
              <template slot="selection" slot-scope="data">
                {{data.item.first_name}} {{data.item.last_name}}
              </template>
                <template v-slot:item="{ item }">
                  {{item.first_name}} {{item.last_name}}
                </template>
            </v-select>
          </div>
          <v-btn color="primary" small outlined @click="addUser(content.telegrams_table,enabledTelegramUsersTable)" style="max-width: 150px;" :disabled="enabledTelegramUsersTable.length === 0">+Add user</v-btn>
          <div v-if="enabledTelegramUsersTable.length === 0">
            You need create more users <span @click="goToTelegramUsers" style="color:#007aff;cursor: pointer;">here</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ProductMenuCategory from "@/components/Constructor/Menu/ProductMenu/ProductMenuCategory";
import ProductMenuSubcategory from "@/components/Constructor/Menu/ProductMenu/ProductMenuSubcategory";
import ProductMenuProduct from "@/components/Constructor/Menu/ProductMenu/ProductMenuProduct";
import TelegramUserService from "../../../../services/telegramUser.service";
export default {
  name: "Products",
  props: ["item", "content","selectedMenuID"],
  data() {
    return {
      userList:[],
      cartSettings:false,
      pageUsage: "category",
      createdProductStartTime: false,
      createdProductEndTime: false,
      editedProductStartTime: false,
      editedProductEndTime: false,
      tags: [
        {
          id: 0,
          value: false,
          name: "The dish is made from frozen products",
          img: "Icons-01.png",
        },
        {
          id: 1,
          value: false,
          name: "Gluten-free product",
          img: "Icons-02.png",
        },
        {
          id: 2,
          value: false,
          name: "Vegetarian ingredients",
          img: "Icons-03.png",
        },
        {
          id: 3,
          value: false,
          name: "Contains mushrooms",
          img: "Icons-05.png",
        },
        {
          id: 4,
          value: false,
          name: "Lactose-free ingredients",
          img: "Icons-06.png",
        },
        { id: 5, value: false, name: "Vegan ingredients", img: "Icons-07.png" },
        { id: 6, value: false, name: "Contains meat", img: "Icons-08.png" },
        {
          id: 7,
          value: false,
          name: "The dish contains seafood",
          img: "Icons-09.png",
        },
        { id: 8, value: false, name: "Chefs choice", img: "Icons-10.png" },
      ],
      currency: [
        { id: 0, value: "$", name: "Dollar $" },
        { id: 1, value: "€", name: "Euro €" },
      ],
      units: [
        { id: 0, value: "g", name: "gram (g)" },
        { id: 1, value: "kg", name: "kilogram (kg)" },
        { id: 2, value: "mg", name: "milligram (mg)" },
        { id: 3, value: "l", name: "liter (l)" },
        { id: 4, value: "ml", name: "milliliter (ml)" },
      ],
      createdCategorySelectedIcon: "",
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: "jpg,svg,webp,gif,png",
        images_upload_handler: async (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), {
            lastModified: new Date().getTime(),
            type: blobInfo.blob().type,
          });
          formData.append(
            "lending_settings_id",
            this.$store.state.landing.settings_id
          );
          formData.append("image", _file);
          formData.append("file", _file);
          const { data } = await this.$axios.post(
            `${process.env.VUE_APP_API_URL}upload-image`,
            formData
          );
          success(data);
        },
        plugins:
          "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
          images_file_types: "jpg,svg,webp,gif,png",
          images_upload_handler: async (blobInfo, success, failure) => {
            let formData = new FormData();
            let _file = new File([blobInfo.blob()], blobInfo.filename(), {
              lastModified: new Date().getTime(),
              type: blobInfo.blob().type,
            });
            formData.append(
              "lending_settings_id",
              this.$store.state.landing.settings_id
            );
            formData.append("image", _file);
            formData.append("file", _file);
            const { data } = await this.$axios.post(
              `${process.env.VUE_APP_API_URL}upload-image`,
              formData
            );
            success(data);
          },
          plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,min_height:200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 250,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        content_style: ".mymention{ color: gray; }",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",

          setup:function(editor){
              editor.ui.registry.addButton('custom_button', {
                  text: 'Add Button',
                  onAction: function() {
                      // Open a Dialog
                      editor.windowManager.open({
                          title: 'Add custom button',
                          body: {
                              type: 'panel',
                              items: [{
                                  type: 'input',
                                  name: 'button_label',
                                  label: 'Button label',
                                  flex: true
                              },{
                                  type: 'input',
                                  name: 'button_href',
                                  label: 'Button href',
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_target',
                                  label: 'Target',
                                  items: [
                                      {text: 'None', value: ''},
                                      {text: 'New window', value: '_blank'},
                                      {text: 'Self', value: '_self'},
                                      {text: 'Parent', value: '_parent'}
                                  ],
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_rel',
                                  label: 'Rel',
                                  items: [
                                      {text: 'No value', value: ''},
                                      {text: 'Alternate', value: 'alternate'},
                                      {text: 'Help', value: 'help'},
                                      {text: 'Manifest', value: 'manifest'},
                                      {text: 'No follow', value: 'nofollow'},
                                      {text: 'No opener', value: 'noopener'},
                                      {text: 'No referrer', value: 'noreferrer'},
                                      {text: 'Opener', value: 'opener'}
                                  ],
                                  flex: true
                              },{
                                  type: 'selectbox',
                                  name: 'button_style',
                                  label: 'Style',
                                  items: [
                                      {text: 'Success', value: 'success'},
                                      {text: 'Info', value: 'info'},
                                      {text: 'Warning', value: 'warning'},
                                      {text: 'Error', value: 'error'}
                                  ],
                                  flex: true
                              }]
                          },
                          onSubmit: function (api) {

                              var html = '<a href="'+api.getData().button_href+'" class="v-btn v-btn--outlined theme--light v-size--small '+api.getData().button_style+'--text" rel="'+api.getData().button_rel+'" target="'+api.getData().button_target+'">'+api.getData().button_label+'</a>';

                              // insert markup
                              editor.insertContent(html);

                              // close the dialog
                              api.close();
                          },
                          buttons: [
                              {
                                  text: 'Close',
                                  type: 'cancel',
                                  onclick: 'close'
                              },
                              {
                                  text: 'Insert',
                                  type: 'submit',
                                  primary: true,
                                  enabled: false
                              }
                          ]
                      });
                  }
              });
          },
      content_css: ["/custom_tinymce.css"],
      },
      error: "",
      dialogCreateCategory: false,
      dialogEditCategory: false,
      dropzoneOptions: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        maxfiles: 3,
        thumbnailMethod: "contain",
        acceptedfiles: "image/*",
        addRemoveLinks: true,
      },
      nameRules: [(v) => !!v || "Category name is required"],
      createName: "",
      selectedCategory: null,
      selectedSubCategory: null,
      deleteItem: null,
      deleteSubCategoryItem: null,
      createdCategory: {
        name: { en: "" },
        files: [],
        hotel_id: "",
        main_file: "",
        pdf_file: "",
        active: 1,
        menu_id: "",
      },
      createdSubCategory: {
        name: { en: "" },
        files: [],
        hotel_id: "",
        main_file: "",
        pdf_file: "",
        active: 1,
        product_category_id: 0,
      },
      editSubCategory: {
        name: { en: "" },
        files: [],
        product_category_id: "",
        main_file: "",
        pdf_file: "",
        active: 1,
        menu_id: "",
      },
      editCategory: {
        name: { en: "" },
        files: [],
        hotel_id: "",
        main_file: "",
        pdf_file: "",
        active: 1,
        menu_id: "",
      },
      successMessage: "",
      headersProduct: [
        { text: "", value: "main_file", align: "center" },
        { text: "Name", value: "name", align: "center", sortable: true },
        {
          text: "Category",
          value: "category_id",
          align: "center",
          sortable: true,
        },
        { text: "Price", value: "price", align: "center", sortable: true },
        { text: "Status", value: "active", align: "center", sortable: true },
        { text: "Options", align: "center", sortable: true },
      ],
      headers: [
        { text: "ID", value: "id", align: "center", sortable: true },
        { text: "Name", value: "name", align: "center", sortable: true },
        {
          text: "Subcategories count",
          value: "sub_categories",
          align: "center",
          sortable: true,
        },
        {
          text: "Product count",
          value: "products",
          align: "center",
          sortable: true,
        },
        {
          text: "Created",
          value: "created_at",
          align: "center",
          sortable: true,
        },
        {
          text: "Status",
          value: "active",
          align: "center",
          sortable: true,
        },
        { text: "Options", align: "center", sortable: true },
      ],
      headersSubCategories: [
        { text: "ID", value: "id", align: "center", sortable: true },
        { text: "Name", value: "name", align: "center", sortable: true },
        {
          text: "Product count",
          value: "products",
          align: "center",
          sortable: true,
        },
        {
          text: "Created",
          value: "created_at",
          align: "center",
          sortable: true,
        },
        {
          text: "Status",
          value: "active",
          align: "center",
          sortable: true,
        },
        { text: "Options", align: "center", sortable: true },
      ],
      dialogCreateProduct: false,
      dialogCreateSubCategory: false,
      dialogEditSubCategory: false,
      createdProduct: {
        name: { en: "" },
        description: { en: "" },
        small_description: { en: "" },
        options: [],
        recommended: [],
        similar: [],
        allergens: "",
        video: "",
        product_category_id: null,
        product_sub_category_id: null,
        main_file: "",
        weight: 0,
        unit: "g",
        active: 1,
        files: [],
        detail_files: [],
        tags: [],
        price: 0,
        currency: "€",
        start_time: "",
        end_time: "",
      },
      editCategoryFilePdf: [],
      editSubCategoryFilePdf: [],
      editedProduct: {
        name: { en: "" },
        description: { en: "" },
        small_description: { en: "" },
        options: [],
        recommended: [],
        similar: [],
        allergens: "",
        video: "",
        product_category_id: null,
        product_sub_category_id: null,
        main_file: "",
        weight: 0,
        unit: "g",
        active: 1,
        files: [],
        detail_files: [],
        tags: [],
        price: 0,
        currency: "",
        start_time: 0,
        end_time: 0,
      },
      deleteProductItem: null,
      dialogEditedProduct: false,
      dialogListSubcategories: false,
      listSubcategories: [],
      dialogListProducts: false,
      styles: false,
    };
  },

  components: {
    ProductMenuProduct,
    ProductMenuSubcategory,
    ProductMenuCategory,
  },
  computed: {
    ...mapState(["landing"]),
    ...mapState("landing", ["icons"]),
    ...mapState("products", ["categories"]),
    enabledTelegramUsersTable(){
      return this.$store.state.telegramUsers .reduce((acc,item) => {
        const candidateIndex = this.content.telegrams_table.find(x => x.id === item.id);
        if(!candidateIndex){
          acc.push(item);
        }
        return acc
      },[])
    },
    enabledTelegramUsers(){
      return this.$store.state.telegramUsers .reduce((acc,item) => {
        const candidateIndex = this.content.telegrams.find(x => x.id === item.id);
        if(!candidateIndex){
          acc.push(item);
        }
        return acc
      },[])
    },
    allProducts() {
      return this.categories.reduce((acc, item) => {
        if (item.products.length) {
          acc = [...acc, ...item.products];
        }
        if (item.sub_categories.length) {
          for (let i = 0; i < item.sub_categories.length; i++) {
            acc = [...acc, ...item.sub_categories[i].products];
          }
        }
        return acc;
      }, []);
    },
    categoriesWithSubcategories() {
      return this.categories.reduce((acc, item) => {
        let obj = item;
        acc.push(obj);
        for (let i = 0; i < item.sub_categories.length; i++) {
          let objSub = item.sub_categories[i];
          objSub.categoryName = obj.name;
          acc.push(objSub);
        }
        return acc;
      }, []);
    },
    activeLang: {
      set: function (val) {},
      get: function () {
        return this.landing.advanced.selected_langs.indexOf(
          this.landing.current_lang
        );
      },
    },
    arrEmailsTable(){
      let arr = []
      for(let i = 0;i<this.content.emails_table.length;i++){
        arr.push(i);
      }
      return arr
    },
    arrTelegramsTable(){
      let arr = []
      for(let i = 0;i<this.content.telegrams_table.length;i++){
        arr.push(i);
      }
      return arr
    },
    arrTelegrams(){
      let arr = []
      for(let i = 0;i<this.content.telegrams.length;i++){
        arr.push(i);
      }
      return arr
    },
    arrEmails(){
      let arr = []
      for(let i = 0;i<this.content.emails.length;i++){
        arr.push(i);
      }
      return arr
    },
  },
  methods: {
    ...mapActions("products", ["getCategoriesList"]),
    telegramUsersAvaliable(user,arr){
      return this.$store.state.telegramUsers.reduce((acc,item) => {
        if(user){
          if(item.id === user.id){
            acc.push(item);
          }
        }
        if (!arr.some(e => e.telegram_key === item.telegram_key)) {
          acc.push(item);
        }
        return acc
      },[])
    },
    goToTelegramUsers(){
      this.landing.order.tab = 6;
      this.$store.commit('setPage','order');
      this.$store.state.tab = 4;
    },
    deleteUser(index,arr){
      arr.splice(index,1);
    },
    addUser(arr,enabledArr){
      let item = JSON.parse(JSON.stringify(enabledArr[0]));
      item.disabled = true;
      arr.push(item);
    },
    deleteEmail(index,arr){
      arr.splice(index,1);
    },
    addEmail(arr){
      arr.push("");
    },
    closeCartSettings(){
      this.cartSettings = false;
    },
    OpenCartSettings(){
      this.cartSettings = true;
    },
    openCategoryNext() {
      let arr = this.$store.state.products.pageProduct.split("/");
      if (arr.length === 3&&arr[0] === 'productList') {
        this.pageUsage = "subcategory";
        this.$store.commit(
          "products/setPageProducts",
          `subcategoriesList/${arr[1]}`
        );
      } else {
        this.pageUsage = "category";
        this.$store.commit("products/setPageProducts", `categoryList`);
      }
    },
    openCategory() {
      this.pageUsage = "category";
      this.$store.commit("products/setPageProducts", `categoryList`);
    },
    openDialogProductsList(item) {
      this.selectedCategory = item;
      this.dialogListProducts = true;
      if (this.pageUsage === "subcategory") {
        this.$store.commit(
          "products/setPageProducts",
          `productList/${this.selectedCategory.product_category_id}/${this.selectedCategory.id}`
        );
      } else {
        this.$store.commit(
          "products/setPageProducts",
          `productList/${this.selectedCategory.id}`
        );
      }
      this.pageUsage = "product";
    },
    showSubcategoriesList(item) {
      this.selectedCategory = item;
      this.listSubcategories = item.sub_categories;
      this.pageUsage = "subcategory";
      this.$store.commit(
        "products/setPageProducts",
        `subcategoriesList/${item.id}`
      );
    },
  },
  async mounted() {
    let id;
    if(this.item.type === 8){
         id = +(this.selectedMenuID+""+this.item.id+'000');
    }else{
        id = this.item.id;
    }
    const payload = {
      hotel_id: this.$route.params.id,
      menu_id: id,
    };
    await this.getCategoriesList(payload);
    if (this.categories.length) {
      this.selectedCategory = this.categories[0];
    }
    for (let i = 0; i < this.landing.selected_langs.length; i++) {
      if (!this.createdCategory.name[this.landing.selected_langs[i]]) {
        this.createdCategory.name[this.landing.selected_langs[i]] =
          this.createdCategory.name["en"].split("").join("");
      }
    }
    this.$store.state.telegramUsers = await TelegramUserService.getUserList(this.$route.params.id);
  },
};
</script>

<style lang="scss">
.lang__tabs > .v-tabs-bar {
  margin: 0;
    border-bottom:1px solid #c2c2c2;
}
</style>
